import './Profile.css';
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import { signOut, getAuth } from "firebase/auth";

import MuiPhoneNumber from 'mui-phone-number';

import { DRIVERS_TABLE } from '../libs/constants.js';

import { doc, setDoc, getFirestore } from 'firebase/firestore';

import { getDriverInfo } from '../libs/utils.js';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

function Profile () {
    const nav = useNavigate();

    const {firebaseApp} = useContext(FirebaseContext);
    const {auth, setAuth} = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [driverInfo, setDriverInfo] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const db = getFirestore(firebaseApp);
    const authFromApp = getAuth(firebaseApp);

    const signOutUser = () => {
        signOut(authFromApp).then(() => {
            setAuth({loggedIn: false, driverInfo: null});
            nav('/signin');
        });
    };

    const saveProfile = () => {
        setIsEdit(false);
        setLoading(true);
        const driverRef = doc(db, DRIVERS_TABLE, driverInfo.id);
        const newDriverInfo =  { firstName: driverInfo.firstName, lastName: driverInfo.lastName, phoneNumber: driverInfo.phoneNumber, email: driverInfo.email };
        setDoc(driverRef, newDriverInfo, { merge: true })
        .then(() => {
            setDriverInfo(newDriverInfo);
            setLoading(false);
        });
    };

    const updateDriverInfo = (update) => {
        const updatedDriverInfo = {...driverInfo};
        if (update?.firstName) {
            updatedDriverInfo.personalInfo.firstName = update.firstName;
        }
        if (update?.lastName) {
            updatedDriverInfo.personalInfo.lastName = update.lastName;
        }
        if (update?.phoneNumber) {
            updatedDriverInfo.personalInfo.phoneNumber = update.phoneNumber;
        }
        if (update?.email) {
            updatedDriverInfo.personalInfo.email = update.email;
        }
        setDriverInfo(updatedDriverInfo);
    };

    /*useEffect(() => {
        if (!loading && !auth?.loggedIn) {
            nav('/signin');
        }
    });*/

    if (loading) {
        if (auth?.loggedIn && auth?.driverInfo) {
            setDriverInfo(auth?.driverInfo);
            setLoading(false);
            return (<div className="Loading"><CircularProgress/></div>);
        } else {
            setLoading(false);
        }
    }  else  if (driverInfo != null) {
        return (<div className="Profile">
                <Card variant="outlined">
                    <CardContent>
                        <div className="SignOut"><Button onClick={signOutUser}>Sign Out</Button></div>
                    {!isEdit && (<>
                        <Stack direction="row" spacing={20}>
                            <Stack direction="column">
                                <h3>First Name</h3>
                                <div>{driverInfo.personalInfo.firstName}</div>
                            </Stack>
                            <Stack direction="column">
                                <h3>Last Name</h3>
                                <div>{driverInfo.personalInfo.lastName}</div>
                            </Stack>
                        </Stack>
                        <h3>Email</h3>
                        <div>{driverInfo.personalInfo.email}</div>
                        <h3>Phone</h3>
                        <div>{driverInfo.personalInfo.phoneNumber}</div>
                        <div className="ProfileButtons"><Button onClick={() => setIsEdit(true)}>Edit Profile</Button></div>
                    </>)}
                    {isEdit && (<>
                        <Stack direction="row" spacing={20}>
                            <Stack direction="column">
                                <h3>First Name</h3>
                                <div><TextField label="First Name" variant="outlined" value={driverInfo.personalInfo.firstName} onChange={(event) => { updateDriverInfo({firstName: event.target.value}); } }/></div>
                            </Stack>
                            <Stack direction="column">
                                <h3>Last Name</h3>
                                <div><TextField label="Last Name" variant="outlined" value={driverInfo.personalInfo.lastName} onChange={(event) => { updateDriverInfo({lastName: event.target.value}); } }/></div>
                            </Stack>
                        </Stack>
                        <h3>Email</h3>
                        <div><TextField label="Email" variant="outlined" value={driverInfo.personalInfo.email} onChange={(event) => { updateDriverInfo({email: event.target.value}); } } sx={{minWidth: 300}}/></div>
                        <h3>Phone</h3>
                        <div><MuiPhoneNumber variant="outlined" label="Phone Number" value={driverInfo.personalInfo.phoneNumber} onChange={(event) => { updateDriverInfo({phoneNumber: event.target.value}); } }/></div>
                        <div className="ProfileButtons"><Button onClick={() => saveProfile()}>Save Profile</Button><Button onClick={() => setIsEdit(false)}>Cancel</Button></div>
                    </>)}

                    </CardContent>
               </Card>
            </div>);
    } else {
        return (<></>);
    }
}

export default Profile;
