import './RideMap.css';
import React, { useState, useEffect } from 'react';

import {Map, Marker, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';

import { VIEWPORT_LIMIT } from '../libs/constants.js';

const placeService = { current: null };

function RideMap(props) {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [fromLatLng, setFromLatLng] = useState(null);
    const [toLatLng, setToLatLng] = useState(null);
    const [centerLatLng, setCenterLatLng] = useState(null);

    const map = useMap();
    const placesLib = useMapsLibrary('places');

    useEffect(() => {
        if (!placesLib || !map) return undefined;

        if (!placeService.current) {
            placeService.current = new placesLib.PlacesService(map);
        }

        if (!placeService.current) {
          return undefined;
        }
        placeService.current.getDetails({placeId: props.from }, (result, status) => {
            if (status === placesLib.PlacesServiceStatus.OK) {
                setFromLatLng(result.geometry.location);
            }
        });

        placeService.current.getDetails({placeId: props.to }, (result, status) => {
            if (status === placesLib.PlacesServiceStatus.OK) {
                setToLatLng(result.geometry.location);
            }
        });
    },[map, placesLib]);

   useEffect(() => {
        if (!fromLatLng || !toLatLng) return undefined;

        const lat = (fromLatLng.lat() + toLatLng.lat()) / 2;
        const lng = (fromLatLng.lng() + toLatLng.lng()) / 2;
        setCenterLatLng({lat: lat, lng: lng});
    }, [fromLatLng, toLatLng]);

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    const getZoom = () => {
        if (!props.duration) {
            return 10;
        }
        const zoomScale = Math.round(props.duration / 900);
        const zoom = 13 - zoomScale;
        if (zoom > 8) {
            return zoom;
        }
        return 8;
    };

    return (
        <div className={viewPortWidth > VIEWPORT_LIMIT? 'Map':'SmallMap'}>
            <Map defaultZoom={getZoom()}  defaultCenter={centerLatLng} gestureHandling={'greedy'}>
              <Directions to={props.to} from={props.from} />
            </Map>
        </div>
    );
}

function Directions(props) {
  const map = useMap();
  const routesLibrary = useMapsLibrary('routes');
  const [directionsService, setDirectionsService] = useState();
  const [directionsRenderer, setDirectionsRenderer] = useState();
  const [routes, setRoutes] = useState([]);
  const [routeIndex, setRouteIndex] = useState(0);
  const selected = routes[routeIndex];
  const leg = selected?.legs[0];
  // Initialize directions service and renderer
  useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({map: map}));
  }, [routesLibrary, map]);

  // Use directions service
  useEffect(() => {
    if (!directionsService || !directionsRenderer) return;

    directionsService
      .route({
        origin: {placeId: props.from},
        destination: {placeId: props.to},
        travelMode: routesLibrary.TravelMode.DRIVING,
        provideRouteAlternatives: true
      })
      .then(response => {
        directionsRenderer.setDirections(response);
        setRoutes(response.routes);
      });

    return () => directionsRenderer.setMap(null);
  }, [directionsService, directionsRenderer]);

  // Update direction route
  useEffect(() => {
    if (!directionsRenderer) return;
    directionsRenderer.setRouteIndex(routeIndex);
  }, [routeIndex, directionsRenderer]);

  if (!leg) return null;

  return (<></>);
}

export default RideMap;