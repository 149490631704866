import './RideDetails.css';
import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { BOOK_RIDES_TABLE } from '../libs/constants.js';
import { printDate } from '../libs/utils.js';

import RideMap from'./RideMap';

function RideDetails(props) {
    const ride = props.ride;

    return (
        <div className="RideDetails">
            <div className="Header">Details</div>
            <div className="DetailInfo">Passengers: {ride.leg.passengers}</div>
            <div className="DetailInfo">Luggage: {ride.leg.bags.luggage}</div>
            <div className="DetailInfo">Irregular Bags: {ride.leg.bags.irregularBags}</div>
            <div className="DetailInfo">Oversized Bags: {ride.leg.bags.overSizedBags}</div>
            <div className="Header">Primary Passenger</div>
            <div className="PrimaryPassenger">First Name: {ride.passenger.firstName}</div>
            <div className="PrimaryPassenger">Last Name: {ride.passenger.lastName}</div>
            <div className="PrimaryPassenger">Email: {ride.passenger.email}</div>
            <div className="PrimaryPassenger">Phone: {ride.passenger.phone}</div>
            <div className="Header">Ryde Info</div>
            <div className="ConfirmationCode">Confirmation Code: {ride.confirmationCode}</div>
            <div className="ServiceLevel">Service Level: {ride.level}</div>
            <div>
                <RideMap from={ride.leg.fromPlaceId} to={ride.leg.toPlaceId} duration={ride.leg?.estimatedDuration?.value || 'N/A'}/>
                <h4>Pickup: {printDate(ride.leg.pickUpDateTime)}</h4>
                <p>{ride.leg.from}</p>
                <h4>Drop-off</h4>
                <p>{ride.leg.to}</p>
                <h4>Estimated Duration</h4>
                <p>{ride.leg?.estimatedDuration?.text || 'N/A'}</p>
            </div>
        </div>);
}

export default RideDetails;