import './App.css';
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { APIProvider } from '@vis.gl/react-google-maps';

import Schedule from './pages/Schedule';
import Profile from './pages/Profile'
import MyActivity from './pages/MyActivity';
import SignIn from './pages/SignIn';
import Layout from './pages/Layout';
import { AuthContext }  from './providers/AuthContext.js';
import { FirebaseContext } from './providers/FirebaseContext.js';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBD__09hW4pjR2saUqZAyH9nyNlrPcwYAQ",
  authDomain: "tuxdev-a23d9.firebaseapp.com",
  projectId: "tuxdev-a23d9",
  storageBucket: "tuxdev-a23d9.appspot.com",
  messagingSenderId: "392547723678",
  appId: "1:392547723678:web:b3d0623597975b7c580e8f",
  measurementId: "G-JJG2GED2EK"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const GOOGLE_MAPS_API_KEY = 'AIzaSyBD__09hW4pjR2saUqZAyH9nyNlrPcwYAQ';

function App() {
  const [auth, setAuth] = useState(null);
  const [firebaseApp, setFirebaseApp] = useState(app);

  return (
    <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AuthContext.Provider value={{auth, setAuth}}>
            <FirebaseContext.Provider value={{firebaseApp, setFirebaseApp}}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Layout />}>
                    <Route index element={<Schedule />} />
                    <Route path="/schedule" element element={<Schedule />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/myactivity" element={<MyActivity />} />
                    <Route path="/signin" element={<SignIn />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </FirebaseContext.Provider>
          </AuthContext.Provider>
        </LocalizationProvider>
    </APIProvider>
  );
}

export default App;
