import { query, collection, where, getDoc, getDocs, doc } from "firebase/firestore";
import { DRIVERS_TABLE } from './constants.js';

export async function  getDriverInfo(db, userId) {
    const driverDoc = await getDocs(query(collection(db, DRIVERS_TABLE), where('uid', '==', userId)));
    if (driverDoc.docs.length === 1) {
        const drive = driverDoc.docs[0].data();
        return {...driverDoc.docs[0].data(), id: driverDoc.docs[0].id };
    }
    return null;
}

export function printDate(dateString) {
    const datePartByDash = dateString.split('-');
    let timezoneOffset = datePartByDash[datePartByDash.length - 1];
    if (timezoneOffset.indexOf(':') === -1) {
        timezoneOffset = timezoneOffset.replace('00', ':00').split('(')[0].trim();
    }
    const formatter = new Intl.DateTimeFormat('en-US', {
        timeStyle: 'long',
        dateStyle: 'long',
        timeZone: `-${timezoneOffset}`,
        });
    return formatter.format(new Date(dateString));
}
