import './RydeListByHour.css';
import React from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import dayjs from 'dayjs';

function RydeListByHour(props) {
    const driverBlocks = () => {
        if (!props.drives) {
            return [];
        }

        const drives = [];
        let lastTimeEnd = 0;
        for (let i = 0; i < props.drives.length; i++) {
            const drive = props.drives[i];
            const pickUpTime = dayjs(drive.leg.pickUpDateTime);
            const dropOffTime = pickUpTime.add(drive.leg.estimatedDuration.value, 'second');
            const timeStart = (pickUpTime.hour() * 60) + pickUpTime.minute();
            const timeEnd = (dropOffTime.hour() * 60) + dropOffTime.minute();
            if (lastTimeEnd < timeStart) {
                const emptyBlockHeight = ((timeStart - lastTimeEnd) * 2);
                drives.push(
                    <div className="RydeDriveBlock" style={{height: emptyBlockHeight}}></div>
                );
            }
            let driveHeight = (timeEnd - timeStart) * 2;
            if (driveHeight < 100) {
                driveHeight = 100;
                lastTimeEnd = timeStart + 50 ;
            } else {
                lastTimeEnd = timeEnd;
            }
            drives.push(
                    <div className="RydeDriveBlockOutline" style={{height: driveHeight}}>
                        <div className="RydeTime">{pickUpTime.hour()}:{pickUpTime.minute() >= 10? pickUpTime.minute() :`0${pickUpTime.minute()}`} - {dropOffTime.hour()}:{dropOffTime.minute() >= 10? dropOffTime.minute() :`0${dropOffTime.minute()}`}</div>
                        <div className="PickUp">Pick Up: {drive.leg.from}</div>
                        <div><Button autoFocus={(i === 0)} onClick={()=> { props.setSelectedDriver(drive)}}>Details</Button></div>
                    </div>
               );

         }
         return drives;
    };

    const hourBlocks = () => {
        const hours = []
        for (let i = 0; i < 24; i++)
        {
            hours.push(<Stack direction="column">
                    <div className="RydeHourBlock" style={{height: 119}}>
                    {`${i}:00`}
                    </div>
                </Stack>);
        }
        return hours;
    };

    return (
        <div className="RydeListByHour">
            <Stack direction="row">
                <Stack direction="column">
                    {hourBlocks()}
                </Stack>
                <Stack direction="column" style={{width: '100%'}}>
                    {driverBlocks()}
                </Stack>
            </Stack>
        </div>);
}

export default RydeListByHour;