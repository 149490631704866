import './Schedule.css'
import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { getFirestore, query, collection, where, getDocs } from "firebase/firestore";

import RideDetails from '../components/RideDetails';
import RydeList from '../components/RydeList';
import RydeListByHour from '../components/RydeListByHour';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import { BOOKED_RIDES_TABLE,  VIEWPORT_LIMIT } from '../libs/constants.js';

function Schedule() {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [loaded, setLoaded] = useState(false);
    const [drives, setDrives] = useState();
    const [selectedDriver, setSelectedDriver] = useState();
    const [viewType, setViewType] = useState('date');

    const nav = useNavigate();
    const {firebaseApp} = useContext(FirebaseContext);
    const {auth, setAuth} = useContext(AuthContext);

    const db = getFirestore(firebaseApp);

    const getDrivesForDay = (driverRydes) => {
        const drivesForTheDay = [];
        for (const driverRyde of driverRydes) {
            const leg = driverRyde.leg;
            const pickUpDateTime = dayjs(leg.pickUpDateTime);
            if (pickUpDateTime.date() === selectedDate.date() &&
                pickUpDateTime.month() === selectedDate.month() &&
                pickUpDateTime.year() === selectedDate.year()) {
                drivesForTheDay.push({passenger: driverRyde.primary, leg: leg, level: driverRyde.level, confirmationCode: driverRyde.confirmationCode});
            }
        }
        return drivesForTheDay;
    };

    const getAllDrives = (driverRydes) => {
        const drives = [];
        const yesterday = dayjs().subtract(1, 'day');
        for (const driverRyde of driverRydes) {
            const leg = driverRyde.leg;
            drives.push({passenger: driverRyde.primary, leg: leg, level: driverRyde.level, confirmationCode: driverRyde.confirmationCode});
        }
        return drives.sort((a, b) => {
            const aPickUpDateTime = dayjs(a.leg.pickUpDateTime);
            const bPickUpDateTime = dayjs(b.leg.pickUpDateTime);
            if (aPickUpDateTime.isBefore(bPickUpDateTime)) {
                return 1;
            } else if (aPickUpDateTime.isAfter(bPickUpDateTime)) {
                return -1;
            } else if (aPickUpDateTime.isSame(bPickUpDateTime)) {
                return 0;
            }
        });
    };

    const fetchRides = async () => {
        getDocs(query(collection(db, BOOKED_RIDES_TABLE),
            where('status', '!=', 'CANCELED')))
              .then((querySnapshot)=>{
                  const rydes = querySnapshot.docs
                      .map((doc) => ({...doc.data(), id:doc.id }));
                  const driverRydes = [];
                  for (const ryde of rydes ) {
                    for (const leg of ryde.legs) {
                        if (leg.driverId === auth?.driverInfo?.id) {
                            const driverRyde = {leg: leg, ...ryde};
                            delete driverRyde.legs;
                            driverRydes.push(driverRyde);
                        }
                    }
                  }
                  if (viewType === 'date') {
                    setDrives(getDrivesForDay(driverRydes));
                  } else if(viewType === 'list') {
                    setDrives(getAllDrives(driverRydes));
                  }
                  setLoaded(true);
              })

      };

    /*useEffect(() => {
        if (!auth || !auth?.loggedIn ) {
           nav('/signin')
        }
    });*/

    useEffect(() => {
        fetchRides();
    }, [selectedDate, viewType]);

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    return (<div className="Schedule">
        <Stack direction="column" spacing={2}>
            <div className="ViewType">
                <RadioGroup row value={viewType} onChange={(event: ChangeEvent) => { setViewType(event.target.value) }}>
                    <FormControlLabel value={'date'} control={<Radio/>} label="Date View"/>
                    <FormControlLabel value={'list'} control={<Radio/>} label="List View"/>
                </RadioGroup>
            </div>

           { viewType === 'date' && (
            <Stack direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                <DateCalendar value={selectedDate} onChange={(newValue) => { setSelectedDate(newValue); setSelectedDriver(null);}} sx={{minWidth: 400}}/>
                <div className="RydeListContainer">
                    <RydeListByHour drives={drives} setSelectedDriver={setSelectedDriver}></RydeListByHour>
                </div>
            </Stack>)}
            { viewType === 'list' && (<div className="RydeList">
                    <RydeList drives={drives} setSelectedDriver={setSelectedDriver}></RydeList>
                </div>)}

            <div className="RydeDetails">
                {!selectedDriver &&
                    <div>No ryde selected.</div>
                }
                {selectedDriver &&
                    <RideDetails ride={selectedDriver} />
                }
            </div>
        </Stack>
    </div>);
}

export default Schedule;
