export const IS_PROD = true;
export const SAFE = 'safe';
export const SECURE = 'secure';
export const ULTRA_SECURE = 'ultra-secure';
export const HOURLY_BASE = 70;
export const BOOKED_RIDES_TABLE = (IS_PROD) ?'prod/tux/BookedRides':'dev/tux/BookedRides';
export const RYDERS_TABLE = (IS_PROD) ?'prod/tux/ryders':'dev/tux/ryders';
export const DRIVERS_TABLE = (IS_PROD) ? 'prod/tux/drivers' : 'dev/tux/drivers';

export const VIEWPORT_LIMIT = 800;
