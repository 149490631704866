import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Outlet } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { VIEWPORT_LIMIT } from '../libs/constants.js';
import { getDriverInfo } from '../libs/utils.js';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

function Layout () {
  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
  const nav =  useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const {auth, setAuth} = useContext(AuthContext);

  const {firebaseApp} = useContext(FirebaseContext);
  const authFromApp = getAuth(firebaseApp);

  const db = getFirestore(firebaseApp);
  onAuthStateChanged(authFromApp, (user) => {
      if (user && !auth?.loggedIn) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        getDriverInfo(db, user.uid).then((driverInfo) => {
            if (driverInfo != null) {
                setAuth({loggedIn: true, user: user, driverInfo: driverInfo});
            }
        });
      } else if (!auth?.loggedIn && location.pathname !== '/signin') {
        nav('/signin');
      }
  });

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const clickHome = () => {
    nav('/schedule');
    handleCloseNavMenu();
  };

  const clickMyActivity = () => {
    nav('/myactivity');
    handleCloseNavMenu();
  };

  const clickProfile = () => {
    nav('/profile');
    handleCloseNavMenu();
  };

  const clickSignIn = () => {
    nav('/signin');
    handleCloseNavMenu();
  }

  useEffect(() => {
    function handleResize() {
        setViewPortWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize)
  });

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
          {!auth?.loggedIn  && (
            <Typography variant="h5" component="div" sx={{ flexGrow: 1}}>
              TUXRydes
            </Typography>)}
            {auth?.loggedIn  && (
              <Box sx={{ flexGrow: 1}}>

                {viewPortWidth > VIEWPORT_LIMIT && (<Typography variant="h5" noWrap component="span">
                  TUXRydes
                </Typography>)}

                <Box display="inline-block" gap={5} sx={{paddingLeft: 10}}>
                  <Button sx= {{color: '#fff'}} onClick={clickHome}>
                    Schedule
                  </Button>

                  <Button sx= {{color: '#fff'}} onClick={clickProfile}>
                    Profile
                  </Button>
                </Box>
              </Box>)}

            {!auth?.loggedIn && (
            <Button color="inherit" onClick={clickSignIn}>Sign In</Button>)}
            {auth?.loggedIn && (
              <AccountCircle />
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <Outlet />
    </>
  )
}

export default Layout;
